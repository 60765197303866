import React from 'react'
import PageContext from '@/context/Page'
import { setPage } from '@/components/common/PageTransition'

let landingPage = true

export default Component => {
  class PageBase extends React.Component {
    constructor (props) {
      super(props)
      this.startHandlers = []
      this.state = {
        instance: this,
        status: 0, // 0: inactive, 1: active
        addStartHandlers: this.addStartHandlers
      }
      setPage(this)
    }
    componentDidMount () {
      if (landingPage) {
        landingPage = false
        this.setState({
          ...this.state,
          status: 1
        })
      }
    }
    componentDidUpdate (prevProps, prevState) {
      if (prevState.status === 0 && this.state.status === 1) {
        this.start()
      }
    }
    componentWillExit () {
      this.setState({
        status: 0
      })
    }
    componentDidEnter () {
      this.setState({
        ...this.state,
        status: 1
      })
    }
    start () {
      this.startHandlers.forEach(handler => handler())
      this.startHandlers = null
    }
    addStartHandlers = (handler) => {
      this.startHandlers.push(handler)
    }
    render () {
      return (
        <PageContext.Provider value={{
          page: this.state
        }}>
          <Component {...this.props} />
        </PageContext.Provider>
      )
    }
  }
  return PageBase
}
