import React from 'react'
import PageContext from '@/context/Page'

const PageConsumer = Component => function PageConsumer (props) {
  return (
    <PageContext.Consumer>
      {({ page }) => <Component page={page} {...props} />}
    </PageContext.Consumer>
  )
}

export default PageConsumer
